
<script>

</script>

<template>
  <div class="resume-page py-5">
    <div class="container">
      <!-- Başlık -->
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h1 class="text-gradient">Lebenslauf</h1>
        <a href="/sevgi_ciplak_resume.pdf" download="Sevgi_Ciplak_Resume.pdf" class="btn btn-primary">
          Lebenslauf herunterladen
        </a>

      </div>

      <!-- Deneyim Bölümü -->
      <section class="experience-section">
        <h2 class="section-title text-primary">Erfahrung</h2>
        <div class="row g-4 mt-3">
          <div class="col-md-6 ">
            <div class="card shadow-sm border-0 h-100">
              <div class="card-body">
                <h6 class="text-muted">Juni 2024 - Heute</h6>
                <h5 class="card-title">Account Manager</h5>
                <p class="text-muted mb-1">Meinestadt.de, Köln (im Homeoffice)</p>
                <p class="text-muted mb-1">Beförderung zum Account Manager nach 5 Monaten durch Erreichung des
                  Jahresziels von über 100%</p>
                  <ul>
                    <li>
                        Betreuung und Ausbau von Bestandskunden
                    </li>
                    <li>
                      Neukundenakquise: Identifikation und Ansprache potenzieller
                      Neukunden in der Region, Telefonate zur Terminvereinbarung und
                      Erstellung von Erstaufträgen
                    </li>
                    <li>
                      Kundenbesuche vor Ort: persönliche Treffen mit Kunden zur Pflege
                      und Intensivierung der Geschäftsbeziehungen.
                    </li>
                    <li>
                      Anzeigenmanagement: Erstellung und Anpassung von
                      Stellenanzeigen basierend auf Kundenfeedback
                    </li>
                    <li>
                      Analyse von Markttrends und Wettbewerbsaktivitäten
                    </li>
                    <li>
                      Soft Skills - Hervorragende Präsentationsfähigkeiten (z. B. in YourfixPräsentationen).
                    </li>
                    <li>
                      Cross-Selling und Up-Selling
                    </li>
                    <li>
                      Verhandlung und Abschluss von Verträgen
                    </li>
                    <li>
                      Reporting: Überwachung der Anzeigenperformance und Erstellung
                      detaillierter Reports zur Ergebnisanalyse für Bestandskunden
                    </li>
                    <li>
                      Teilnahme an Branchenveranstaltungen zur
                      Unternehmensförderung.
                    </li>
                    <li>
                      Leadership-Erfahrung durch enge Zusammenarbeit und Anleitung
                      von Junior-Kollegen.
                    </li>
                  </ul>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card shadow-sm border-0 h-100">
              <div class="card-body">
                <h6 class="text-muted">Jan. 2024 - Juni 2024</h6>
                <h5 class="card-title">Sales Manager</h5>
                <p class="text-muted mb-1">Meinestadt.de, Köln (im Homeoffice)</p>

                  <ul>
                    <li>
                      Akquise und Ansprache potenzieller Neukunden
                    </li>
                    <li>
                      Einhaltung der KPIs
                    </li>
                    <li>
                      Durchführung von Verkaufsgesprächen und Produktpräsentationen
                    </li>
                    <li>
                      Ermittlung von Kundenbedürfnissen und Erstellung
                      maßgeschneiderter Angebote
                    </li>
                    <li>
                      Verhandlung und Abschluss von Verträge
                    </li>
                  </ul>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card shadow-sm border-0 h-100">
              <div class="card-body">
                <h6 class="text-muted">Aug. 2022 - Dez 2023</h6>
                <h5 class="card-title">Stellvertretende Teamleitung Customer
                  Service</h5>
                <p class="text-muted mb-1">Sparkasse, Forchheim</p>
                   <ul>
                    <li>
                      Leitung und Motivation des Kundendienstteams
                    </li>
                    <li>
                      Erstellung und Verwaltung von Dienstplänen zur effektiven
                      Personaleinsatzplanung
                    </li>
                    <li>
                      Telefonische Beratung und Unterstützung von Kunden
                    </li>
                    <li>
                      Administrative Aufgaben zur Unterstützung der täglichen Abläufe
                    </li>
                    <li>
                      Schulung und Einarbeitung neuer Mitarbeiter sowie Betreuung von
                      Auszubildenden
                    </li>
                    <li>
                      Durchführung von Controlling- und Reporting-Aktivitäten zur
                      Leistungsüberwachung
                    </li>
                    <li>
                      Sicherstellung der Einhaltung von Leistungskennzahlen und
                      Organisation des täglichen Geschäfts
                    </li>
                  </ul>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card shadow-sm border-0 h-100">
              <div class="card-body">
                <h6 class="text-muted">Sept. 2019 - Juli 2022</h6>
                <h5 class="card-title">Consultant for Dialogue and Customer
                  Communication</h5>
                <p class="text-muted mb-1">Sparkasse, Forchheim</p>

                 <ul>
                   <li>
                     Kundenberatung: Professionelle Kommunikation und
                     Konfliktbewältigung
                   </li>
                   <li>
                     Marketing und Vertriebsstrategien: Planung und Umsetzung von
                     Kampagnen und Verkaufsstrategien
                   </li>
                   <li>
                     Marktforschung: Analyse von Markt- und Kundendaten
                   </li>
                   <li>
                     Projektplanung/Projektmanagement: Koordination und Steuerung
                     von Marketingprojekten
                   </li>
                   <li>
                     Reklamationsmanagement: Bearbeitung und Lösung von
                     Kundenbeschwerden
                   </li>
                 </ul>

              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Eğitim Bölümü -->
      <section class="education-section mt-5">
        <h2 class="section-title text-danger">Ausbildung</h2>
        <div class="row g-4 mt-3">
          <div class="col-md-6">
            <div class="card shadow-sm border-0">
              <div class="card-body">
                <h6 class="text-muted">Sept. 2019 - Juli 2022</h6>
                <h5 class="card-title">Berufsschule, Forchheim</h5>
                <p>Consultant for Dialogue and Customer
                  Communication</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Yetenekler Bölümü -->
      <section class="skills-section mt-5">
        <h2 class="section-title text-purple">Fähigkeiten</h2>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="skill-card">
              <h5 class="skill-title"><i class="fas fa-bullseye text-purple me-2"></i> Fachliche Fähigkeiten</h5>
              <ul class="list-inline mt-3">
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Salesforce</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Kommunikationsstärke</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">People Management</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Jira</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Analysefähigkeit</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2 mt-2">Zeitmanagement</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Confluence</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">MS Office</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Trello</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Genesys</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Projektmanagement</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2 mt-2">Chanceerkennung</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2 mt-2">Teamwork</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2 mt-2">Social Media / Google ads</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2 mt-2">NetSuite</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="skill-card">
              <h5 class="skill-title"><i class="fas fa-language text-purple me-2"></i> Sprachen</h5>
              <ul class="list-inline mt-3">
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Deutsch - Muttersprache</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">Turkish - Muttersprache</li>
                <li class="list-inline-item badge bg-light text-dark p-2 me-2">English - B1</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
/* Genel Arka Plan ve Metin Stilleri */
body {
  background-color: #f8f9fa;
  color: #333;
}

/* Başlık Stilleri */
.text-gradient {
  background: linear-gradient(45deg, #ff6a00, #ee0979);
  -webkit-background-clip: text;
  color: transparent;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
}

.text-purple {
  color: #6f42c1;
}

/* Kartlar */
.card {
  border-radius: 10px;
}

.skill-card .skill-title {
  font-weight: bold;
}

.skill-card ul {
  padding: 0;
  list-style: none;
}

/* Yetenek ve Dil Rozetleri */
.badge {
  font-size: 14px;
}
.skills-section {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
  overflow: visible;
}
</style>
